import { useEffect, useRef, useState } from 'react';
import AddressAutoComplete from '../Pages/Dashboard/Components/AddressAutoComplete';
import PropTypes from 'prop-types';
import Logger from '../../Utils/Logger';
import axiosInstance from '../../Utils/axiosInstance';
import { CITIES_STATE_COUNTRY_ENDPOINT } from '../../Utils/Endpoints';

const AddressForm = ({
  handlePreviousStep,
  handleNextStep,
  setLocationType,
  locationType,
  setSelectedAddress,
  setSelectedCity,
  locationName,
  setLocationName,
  city,
  setCity,
  address,
  setAddress,
  selectedAddress,
}) => {
  const [cityList, setCityList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Separate state for each tab
  const [querySpecific, setQuerySpecific] = useState('');
  const [suggestionsSpecific, setSuggestionsSpecific] = useState([]);
  const [isOpenSpecific, setIsOpenSpecific] = useState(false);

  const [queryCity, setQueryCity] = useState('');
  const [suggestionsCity, setSuggestionsCity] = useState([]);
  const [isOpenCity, setIsOpenCity] = useState(false);

  const inputRefSpecific = useRef(null);
  const inputRefCity = useRef(null);
  const dropdownRefSpecific = useRef(null);
  const dropdownRefCity = useRef(null);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(CITIES_STATE_COUNTRY_ENDPOINT);
      if (response.data.code === 200) {
        setCityList(response.data.data);
      }
    } catch (error) {
      Logger.error(error, isLoading);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle initial city value and updates
  useEffect(() => {
    if (city && cityList.length > 0) {
      const selected = cityList.find(
        (item) => item?.city_state_country === city
      );
      if (selected) {
        setQuerySpecific(selected.city_state_country);
        setQueryCity(selected.city_state_country);
        setSelectedCity(selected);
      }
    }
  }, [city, cityList, setSelectedCity]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefSpecific.current &&
        !dropdownRefSpecific.current.contains(event.target) &&
        inputRefSpecific.current &&
        !inputRefSpecific.current.contains(event.target)
      ) {
        setIsOpenSpecific(false);
      }
      if (
        dropdownRefCity.current &&
        !dropdownRefCity.current.contains(event.target) &&
        inputRefCity.current &&
        !inputRefCity.current.contains(event.target)
      ) {
        setIsOpenCity(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filterSuggestions = (input, cityList) => {
    if (!input) return [];
    return cityList.filter((item) =>
      item.city_state_country.toLowerCase().includes(input.toLowerCase())
    );
  };

  const handleInputChangeSpecific = (e) => {
    const value = e.target.value;
    setQuerySpecific(value);
    setSuggestionsSpecific(filterSuggestions(value, cityList));
    setIsOpenSpecific(true);
    // Clear selected city if input is empty
    if (!value) {
      setCity('');
      setSelectedCity(null);
    }
  };

  const handleInputChangeCity = (e) => {
    const value = e.target.value;
    setQueryCity(value);
    setSuggestionsCity(filterSuggestions(value, cityList));
    setIsOpenCity(true);
    // Clear selected city if input is empty
    if (!value) {
      setCity('');
      setSelectedCity(null);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setQuerySpecific(suggestion.city_state_country);
    setCity(suggestion.city_state_country);
    setSelectedCity(suggestion);
    setSuggestionsSpecific([]);
    setIsOpenSpecific(false);
  };

  const handleSelectSuggestionCity = (suggestion) => {
    setQueryCity(suggestion.city_state_country);
    setCity(suggestion.city_state_country);
    setSelectedCity(suggestion);
    setSuggestionsCity([]);
    setIsOpenCity(false);
  };

  return (
    <>
      <ul className="mb-3 nav nav-tabs nav-warning" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className={`nav-link ${locationType === 'SPECIFIC_LOCATION' ? 'active' : ''}`}
            data-bs-toggle="tab"
            href="#dangerhome"
            role="tab"
            aria-selected={locationType === 'SPECIFIC_LOCATION'}
            onClick={() => setLocationType('SPECIFIC_LOCATION')}
          >
            <div className="d-flex align-items-center">
              <div className="tab-title">Specific location</div>
            </div>
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className={`nav-link ${locationType === 'CITY' ? 'active' : ''}`}
            data-bs-toggle="tab"
            href="#dangerProfile"
            role="tab"
            aria-selected={locationType === 'CITY'}
            onClick={() => setLocationType('CITY')}
            tabIndex="-1"
          >
            <div className="d-flex align-items-center">
              <div className="tab-title">I only got a City</div>
            </div>
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <div
          className={`tab-pane fade ${locationType === 'SPECIFIC_LOCATION' ? 'show active' : ''}`}
          id="dangerhome"
          role="tabpanel"
        >
          {locationType === 'SPECIFIC_LOCATION' && (
            <div className="row g-3 mt-3">
              <div className="col-12">
                <div className="relative w-100">
                  <label htmlFor="cityNameSpecific" className="form-label">
                    City
                  </label>
                  <input
                    ref={inputRefSpecific}
                    type="text"
                    id="cityNameSpecific"
                    className="form-control"
                    value={querySpecific}
                    onChange={handleInputChangeSpecific}
                    placeholder="Search for a City *"
                    autoComplete="off"
                    required
                  />
                  {isOpenSpecific && suggestionsSpecific.length > 0 && (
                    <div
                      ref={dropdownRefSpecific}
                      className="dropdown-menu show position-absolute mt-1 shadow overflow-auto form-select"
                      style={{ maxHeight: '240px', width: '96%' }}
                    >
                      {suggestionsSpecific.map((suggestion, index) => (
                        <div
                          key={index}
                          className="dropdown-item py-2 cursor-pointer"
                          onClick={() => handleSelectSuggestion(suggestion)}
                        >
                          <div className="d-flex align-items-center">
                            {suggestion.city_state_country}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="InputUsername" className="form-label">
                  Address
                </label>
                <AddressAutoComplete
                  value={address}
                  onAddressChange={setAddress}
                  setSelectedAddress={setSelectedAddress}
                />
              </div>
              <div className="col-12">
                <label htmlFor="locationName" className="form-label">
                  Location Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="locationName"
                  value={locationName}
                  required
                  onChange={(e) => setLocationName(e.target.value)}
                  placeholder="Location Name*"
                />
              </div>
            </div>
          )}
        </div>
        <div
          className={`tab-pane fade ${locationType === 'CITY' ? 'show active' : ''}`}
          id="dangerProfile"
          role="tabpanel"
        >
          <div className="col-12 mt-3">
            <div className="relative w-100">
              <label htmlFor="cityNameCity" className="form-label">
                City
              </label>
              <input
                ref={inputRefCity}
                type="text"
                id="cityNameCity"
                className="form-control"
                value={queryCity}
                onChange={handleInputChangeCity}
                placeholder="Search for a City *"
                autoComplete="off"
                required
              />
              {isOpenCity && suggestionsCity.length > 0 && (
                <div
                  ref={dropdownRefCity}
                  className="dropdown-menu show position-absolute mt-1 shadow overflow-auto form-select"
                  style={{ maxHeight: '240px', width: '96%' }}
                >
                  {suggestionsCity.map((suggestion, index) => (
                    <div
                      key={index}
                      className="dropdown-item py-2 cursor-pointer"
                      onClick={() => handleSelectSuggestionCity(suggestion)}
                    >
                      <div className="d-flex align-items-center">
                        {suggestion.city_state_country}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="d-flex align-items-center gap-3">
            <button
              className="btn btn-outline-secondary px-4 text-nowrap"
              onClick={handlePreviousStep}
              type="button"
            >
              <i className="bx bx-left-arrow-alt me-2"></i>
              Previous
            </button>
            <button
              className="btn btn-outline-warning px-4 text-nowrap"
              onClick={handleNextStep}
              disabled={
                locationType === 'SPECIFIC_LOCATION' &&
                Object.keys(selectedAddress).length > 0
                  ? !selectedAddress?.zip_code
                  : false
              }
              type="button"
            >
              Next<i className="bx bx-right-arrow-alt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

AddressForm.propTypes = {
  handlePreviousStep: PropTypes.func,
  handleNextStep: PropTypes.func,
  locationType: PropTypes.string,
  setLocationType: PropTypes.func,
  setSelectedAddress: PropTypes.func,
  setSelectedCity: PropTypes.func,
  locationName: PropTypes.func,
  setLocationName: PropTypes.func,
  city: PropTypes.string,
  setCity: PropTypes.func,
  address: PropTypes.string,
  setAddress: PropTypes.func,
  selectedAddress: PropTypes.object,
};

export default AddressForm;
